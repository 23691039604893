/**
 * @typedef {Object} Song
 * @property {string} url
 * @property {string} playlistTitle
 * @property {string} title
 */
class MusicPlayer
{
    #playerContainer;
    #playerTitleTop;
    #playerTitleBottom;
    #playerShowHideButton;
    #playerPrevButton;
    #playerInfoButton;
    #playerPlayPauseButton;
    #playerNextButton;
    #playerSeeker;
    #playerSeekerDisplayBar;

    /**
     * @type {Song[]}
     */
    #queue = [];
    #currentSongIndex = -1;
    /**
     * @type {HTMLAudioElement|null}
     */
    #audioObject = null;

    constructor()
    {
        this.#playerContainer = document.getElementById('music-player-container');
        if (this.#playerContainer == null)
            throw new Error('Player not found in page.');

        this.#playerTitleTop = document.getElementById('player-title-large');
        this.#playerTitleBottom = document.getElementById('player-title');
        this.#playerShowHideButton = document.getElementById('player-show-hide');
        this.#playerPrevButton = document.getElementById('player-prev-button');
        this.#playerInfoButton = document.getElementById('player-info-button');
        this.#playerPlayPauseButton = document.getElementById('player-play-pause-button');
        this.#playerNextButton = document.getElementById('player-next-button');
        this.#playerSeeker = document.getElementById('player-seeker');
        this.#playerSeekerDisplayBar = document.getElementById('player-seeker-bar');

        const self = this;

        this.#playerPrevButton.addEventListener('click', async function () {
            await self.prevSong();
        });

        this.#playerPlayPauseButton.addEventListener('click', async function () {
            await self.togglePlay();
        });

        this.#playerNextButton.addEventListener('click', async function () {
            await self.nextSong();
        });

        this.#playerShowHideButton.addEventListener('click', async function () {
            await self.toggleShow();
        });

        const playerSeeker = this.#playerSeeker;
        playerSeeker.addEventListener('click', function (e) {
            const rect = playerSeeker.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const percentage = Math.min(1, Math.max(0, x / rect.width));
            self.seek(percentage);
        });
    }

    updateView()
    {
        const song = this.#queue[this.#currentSongIndex];
        const playerTitleTop = this.#playerTitleTop;

        const showHideButton = this.#playerShowHideButton;
        playerTitleTop.removeChild(showHideButton);
        playerTitleTop.innerText = song.title;
        playerTitleTop.appendChild(showHideButton);
        this.#playerTitleBottom.innerText = song.playlistTitle;
    }

    updateBar()
    {
        const audioObject = this.#audioObject;
        if (audioObject !== null)
            this.#playerSeekerDisplayBar.style.width = (Math.min(1, Math.max(0, audioObject.currentTime / audioObject.duration)) * 100) + '%';
    }

    seek(percentage)
    {
        const audioObject = this.#audioObject;
        if (audioObject !== null)
            audioObject.fastSeek(audioObject.duration * percentage);
    }

    toggleShow(forcedValue = undefined)
    {
        const container = this.#playerContainer;
        container.classList.remove('hidden');
        container.classList.toggle('shown', forcedValue);
    }

    async togglePlay()
    {
        const audioObject = this.#audioObject;
        if (audioObject !== null)
            if (audioObject.paused)
                await audioObject.play();
            else
                audioObject.pause();
    }

    async nextSong()
    {
        await this.playSong(this.#currentSongIndex + 1);
    }

    async prevSong()
    {
        await this.playSong(this.#currentSongIndex - 1);
    }

    async playSong(songIndex)
    {
        const queue = this.#queue;
        const queueLength = queue.length;

        if (queueLength === 0)
            return;

        songIndex = (songIndex % queueLength + queueLength) % queueLength;

        const self = this;
        if (this.#audioObject !== null)
            this.#audioObject.pause();

        this.#currentSongIndex = songIndex;
        const song = queue[this.#currentSongIndex];
        const audioObject = new Audio(song.url);
        try
        {
            audioObject.ontimeupdate = function () {
                self.updateBar();
            }

            const playButton = this.#playerPlayPauseButton;
            audioObject.onpause = function ()
            {
                playButton.classList.toggle('paused', true);
            }

            audioObject.onplay = function ()
            {
                playButton.classList.toggle('paused', false);
            }

            audioObject.onerror = function ()
            {
                playButton.classList.toggle('paused', true);
            }

            this.#audioObject = audioObject;
            await audioObject.play();
            this.toggleShow(true);
        }
        catch (e)
        {
            this.#playerPlayPauseButton.classList.toggle('paused', true);
        }

        this.updateView();
    }

    /**
     * @param {Song} song
     * @param {boolean} addToQueue
     */
    async loadSong(song, addToQueue = false)
    {
        if (!addToQueue)
            this.#queue = [];

        this.#queue.push(song);

        if (!addToQueue)
            await this.playSong(0);
    }

    /**
     * @param {Song[]} songs
     * @param {boolean} addToQueue
     */
    async loadSongs(songs, addToQueue = false)
    {
        for (let i = 0; i < songs.length; i++) {
            await this.loadSong(songs[i], !(i === 0 && !addToQueue));
        }
    }
}

window.MusicPlayer = MusicPlayer;
